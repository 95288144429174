import { IStyles } from '../models/app/IStyles';

export const STYLES: IStyles = {
	components: {
		app: {
			children: {
				wrapper: {
					font: {
						family: 'Gordita-Regular',
					},
				},
				headerWrapper: {
					text: {
						align: 'center',
					},
					overrides: [
						{
							selector: 'h1',
							style: {
								display: 'inline-block',
							},
						},
					],
				},
				loadMoreButtonWrapper: {
					text: {
						align: 'center',
					},
					padding: {
						top: '20px',
					},
					overrides: [
						{
							selector: 'button',
							style: {
								border: {
									_value: 'solid 2px #1a81ff',
								},
								text: {
									transform: 'uppercase',
								},
								color: '#1a81ff',
								font: {
									size: '14px',
									weight: 'bold',
								},
								lineHeight: '16px',
								padding: {
									_value: '15px',
								},
								margin: {
									left: '-5px',
									right: '-5px',
								},
								cursor: 'pointer',
							},
						},
					],
				},
			},
		},
		fundCard: {
			children: {
				wrapper: {
					position: 'relative',
					border: {
						top: {
							_value: '1px solid #cccccc',
						},
					},
					overrides: [
						{
							selector: 'div.breakpoint__medium-up',
							style: {
								overrides: [
									{
										selector: '> [data-selector="base-grid-wrapper"] > [data-selector="base-grid-row"]',
										style: {
											justifyContent: 'space-between',
										},
									},
									{
										selector: '> [data-selector="base-grid-wrapper"] > [data-selector="base-grid-row"] > [data-selector="base-grid-column"]:nth-child(1)',
										style: {
											width: '50px',
										},
									},
									{
										selector: '> [data-selector="base-grid-wrapper"] > [data-selector="base-grid-row"] > [data-selector="base-grid-column"]:nth-child(2)',
										style: {
											min: {
												width: '300px',
											},
											max: {
												width: '300px',
											},
										},
									},
									{
										selector: '> [data-selector="base-grid-wrapper"] > [data-selector="base-grid-row"] > [data-selector="base-grid-column"]:nth-child(3)',
										style: {
											width: 'auto',
											flex: {
												grow: '1',
											},
											overrides: [
												{
													selector: '> [data-selector="base-grid-component"] > [data-selector="base-grid-wrapper"] > [data-selector="base-grid-row"]',
													style: {
														justifyContent: 'space-between',
														overrides: [
															{
																selector: '> [data-selector="base-grid-column"]:nth-child(2)',
																style: {
																	max: {
																		width: '300px',
																	},
																	min: {
																		width: '300px',
																	},
																},
															},
														],
													},
												},
											],
										},
									},

								],
							},
						},
						{
							selector: '[data-selector="expand-button"] button, [data-selector="collapse-button"] button',
							style: {
								background: {
									color: 'transparent',
								},
								cursor: 'pointer',
							},
						},
					],
				},
			},
		},
	},
	theme: {
		base: {
			grid: {
				children: {
					row: {
						display: 'flex',
						flex: {
							direction: 'row',
						},
					},
					column: {
						display: 'flex',
						flex: {
							direction: 'column',
						},
					},
				},
			},
			heading: {
				children: {
					h1: {
						color: '#333333',
						font: {
							size: '32px',
							weight: 'normal',
						},
						lineHeight: '34px',
						text: {
							transform: 'uppercase',
						},
						margin: {
							_value: '0 0 30px 0',
						},
					},
					h2: {
						color: '#333333',
						font: {
							size: '20px',
							weight: 'bold',
						},
						lineHeight: '22px',
					},
				},
			},
			text: {
				font: {
					size: '16px',
				},
				margin: {
					bottom: '10px',
				},
				overrides: [
					{
						selector: 'button',
						style: {
							display: 'none',
						},
					},
				],
			},
			button: {
				border: {
					_value: 'none',
				},
				margin: {
					top: '16px',
				},
				cursor: 'pointer',
				selectors: {
					':focus': {
						outline: {
							_value: 'none',
						},
					},
				},
			},
			morningStar: {
				children: {
					morningStarIconWrapper: {
						min: {
							width: '16px',
						},
					},
				},
				display: 'flex',
				flex: {
					wrap: 'wrap',
				},
				justifyContent: 'flex-start',
				width: '100%',
			},
		},
		fact: {
			summaryBlocks: {
				children: {
					row: {
						display: 'flex',
					},
				},
			},
			summaryBlock: {
				children: {
					wrapper: {
						text: {
							align: 'center',
						},
						padding: {
							_value: '10px',
						},
					},
				},
			},
			fact: {
				children: {
					title: {
						font: {
							weight: 'normal',
							size: '12px',
						},
						color: '#777777',
						text: {
							align: 'left',
						},
						margin: {
							bottom: '10px',
						},
					},
					value: {
						font: {
							weight: 'bold',
							size: '16px',
						},
						color: '#333333',
						text: {
							align: 'left',
						},
					},
				},
			},
		},
	},
};