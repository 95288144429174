import React, { Component, Fragment } from 'react';
import { IAppProps } from './models/IAppProps';
import { IAppState } from './models/IAppState';
import { inject, observer } from 'mobx-react';
import Wrapper from './styledComponents/Wrapper';
import AccordionWrapper from './styledComponents/AccordionWrapper';
import AccordionPanel from './styledComponents/AccordionPanel';
import HeaderWrapper from './styledComponents/HeaderWrapper';
import LoadMoreButtonWrapper from './styledComponents/LoadMoreButtonWrapper';
import RedirectButtonWrapper from './styledComponents/RedirectButtonWrapper';
import DebugInfoAction from '../shared/DebugInfoAction';
import NoDataPlaceholder from '@kurtosys/ksys-app-components/dist/components/base/NoDataPlaceholder';
import CardGridWrapper from './styledComponents/CardGridWrapper';
import CardGridRow from './styledComponents/CardGridRow';
import Disclaimer from '@kurtosys/ksys-app-components/dist/components/overview/Disclaimer';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Loading from '../shared/Loading';
import SkeletonLoader from '../shared/SkeletonLoader';
import Application from '@kurtosys/ksys-app-components/dist/components/base/Application';
import Carousel from '@kurtosys/ksys-app-components/dist/components/base/Carousel';
import FundCard from '../FundCard';
import Heading from '@kurtosys/ksys-app-components/dist/components/base/Heading';
import RedirectButton from '@kurtosys/ksys-app-components/dist/components/base/RedirectButton';
import Button from '@kurtosys/ksys-app-components/dist/components/base/Button';
import Card from '@kurtosys/ksys-app-components/dist/components/base/Card';
import LoadingIndicator from '@kurtosys/ksys-app-components/dist/components/base/LoadingIndicator';
import { AppStore } from './stores/AppStore';

@inject('appStore', 'queryStore')
@observer
export class App extends Component<IAppProps, IAppState> {
	static configurationKey: 'app' = 'app';
	static styleKey: 'app' = 'app';
	constructor(props: IAppProps) {
		super(props);
	}
	renderHeader(appStore: AppStore) {
		const { headingProps } = appStore;
		return (
			<HeaderWrapper>
				<Heading { ...headingProps } />
			</HeaderWrapper>
		);
	}
	renderAccordion(appStore: AppStore, gridCardComponents: JSX.Element[]) {
		const { mode, accordionProps } = appStore;
		if (mode === 'accordion' && !accordionProps) {
			console.warn('no accordionProps provided');
		}
		if (mode === 'accordion' && accordionProps) {
			return (
				<AccordionWrapper>
					{ gridCardComponents.map((gridCardComponent, index) => {
						return (
							<AccordionPanel
								key={ `fund-card-accordion-${ index }` }
							>
								{ gridCardComponent }
							</AccordionPanel>
						);
					}) }
				</AccordionWrapper>
			);
		}
		return;
	}
	renderCarousel(appStore: AppStore, gridCardComponents: JSX.Element[]) {
		const { mode, carouselProps } = appStore;
		if (mode === 'carousel' && !carouselProps) {
			console.warn('no carouselProps provided');
		}
		if (mode === 'carousel' && carouselProps) {
			return <Carousel { ...carouselProps }>{ gridCardComponents }</Carousel>;
		}
		return;
	}
	renderGrid(appStore: AppStore, gridCardComponents: JSX.Element[]) {
		const { mode, gridDisplayProps } = appStore;
		const cardGridRows = [];
		if (mode === 'grid' && !gridDisplayProps) {
			console.warn('no gridDisplayProps provided');
		}
		if (mode === 'grid' && gridDisplayProps) {
			const cardsPerRow = gridDisplayProps.cardsPerRow || 3;
			let cardGridRow = [];
			let cardCount = 0;
			for (let i = 0; i < gridCardComponents.length; i++) {
				cardGridRow.push(gridCardComponents[i]);
				cardCount++;
				if (cardCount === cardsPerRow) {
					cardCount = 0;
					cardGridRows.push(cardGridRow);
					cardGridRow = [];
				}
			}
			if (cardCount > 0) {
				cardGridRows.push(cardGridRow);
				cardGridRow = [];
				cardCount = 0;
			}
			return (
				<CardGridWrapper data-selector="app-grid">
					{ cardGridRows.map((row, index) => (
						<CardGridRow key={ `card-row-${ index }` } data-selector="app-grid-row">
							{ row }
						</CardGridRow>
					)) }
				</CardGridWrapper>
			);
		}
		return;
	}
	renderDefault(appStore: AppStore, gridCardComponents: JSX.Element[]) {
		const { mode } = appStore;
		if (!mode) {
			return gridCardComponents;
		}
		return;
	}
	renderRedirectButton(appStore: AppStore) {
		const { globalRedirectButtonProps } = appStore;
		if (globalRedirectButtonProps) {
			return (
				<RedirectButtonWrapper>
					<RedirectButton { ...globalRedirectButtonProps } />
				</RedirectButtonWrapper>
			);
		}
		return null;
	}
	renderLoadMore(appStore: AppStore) {
		const { isLoadMoreEnabled, showAllCards, loadingAllCards, toggleShowAllCards } = appStore;
		if (isLoadMoreEnabled) {
			if (!showAllCards || loadingAllCards) {
				return (
					<LoadMoreButtonWrapper>
						{ !showAllCards && <Button text="Load More" onClick={ () => toggleShowAllCards() } /> }
						{ loadingAllCards && <LoadingIndicator /> }
					</LoadMoreButtonWrapper>
				);
			}
		}
		return null;
	}
	render() {
		const { className, appStore, selector, queryStore } = this.props;
		if (!appStore || !queryStore) {
			return null;
		}
		const { isBootstrapped, skeletonLoader, skeletonLoaders } = appStore;
		if (!isBootstrapped) {
			if (skeletonLoader) {
				return <SkeletonLoader config={ skeletonLoader } variables={ skeletonLoaders && skeletonLoaders.variables } />;
			}
			return <Loading />;
		}
		const { theme, show, showPlaceholder, noDataPlaceholder: noDataPlaceholderText, noDataPlaceholderDisclaimer, libraryComponentsConfiguration, appCardProps, getTranslateFunction, culture, assets, assetCacheOptions, assetRegisters, getAccessibilityTextFunction, gridCards } = appStore;

		if (!show) {
			return null;
		}

		const gridCardComponents = (gridCards || []).map((gridCardProps, index) => {
			return <FundCard key={ `fundCard${ index }` } { ...gridCardProps } />;
		});
		const noDataPlaceholder =
			(noDataPlaceholderDisclaimer && <Disclaimer { ...noDataPlaceholderDisclaimer } />) ||
			(noDataPlaceholderText && <NoDataPlaceholder>{ noDataPlaceholderText }</NoDataPlaceholder>);

		const translate = getTranslateFunction();
		const accessibilityText = getAccessibilityTextFunction();
		return (
			<Application theme={ theme } configuration={ libraryComponentsConfiguration } translate={ translate } culture={ culture } assets={ assets } assetCacheOptions={ assetCacheOptions } assetRegisters={ assetRegisters } accessibilityText={ accessibilityText }>
				<DebugInfoAction appStore={ appStore } />
				<Wrapper className={ className }>
					<Card { ...appCardProps }>
						{ showPlaceholder && noDataPlaceholder }
						{ !showPlaceholder && (
							<Fragment>
								{ this.renderHeader(appStore) }
								{ this.renderAccordion(appStore, gridCardComponents) }
								{ this.renderCarousel(appStore, gridCardComponents) }
								{ this.renderGrid(appStore, gridCardComponents) }
								{ this.renderDefault(appStore, gridCardComponents) }
								{ this.renderRedirectButton(appStore) }
								{ this.renderLoadMore(appStore) }
							</Fragment>
						) }
					</Card>
				</Wrapper>
			</Application >
		);
	}
}

export default InjectedStyledComponent(App, 'app');
