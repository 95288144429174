import { computed, action } from 'mobx';
import { FundCardStoreBase } from '../../../common/FundCardStoreBase';
import { IDefinitionListConfiguration } from '../models';
import { IDefinitionListProps } from '@kurtosys/ksys-app-components/dist/components/base/DefinitionList/models';

export class DefinitionListStore extends FundCardStoreBase {
	static componentKey: 'definitionList' = 'definitionList';

	@computed
	get configuration(): IDefinitionListConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(DefinitionListStore.componentKey);
		}
	}

	@action
	async initialize(): Promise<void> {

	}

	@computed
	get definitionListProps(): IDefinitionListProps | undefined {
		const summaryBlocksProps = this.configuration && this.configuration.definitionListProps;
		const props = this.mergeQueriesAndProps<IDefinitionListProps>(summaryBlocksProps);
		return props;
	}
}