import { observable, action, computed } from 'mobx';
import { query, utils } from '@kurtosys/ksys-app-template';
import { QueryContext } from '@kurtosys/ksys-api-client/dist/query/QueryContext';
import { QueryStore as QueryStoreBase } from '@kurtosys/ksys-app-components/dist/shared/QueryStore';
import { IQueryContextClient } from '@kurtosys/ksys-api-client/dist/models/query/IQueryContextClient';
import { StoreContext } from '../../../configuration/StoreContext';
import { IInputs } from '../../../models/app/IInputs';
import { IEntitiesRequestOptions } from '../../../models/app/IEntitiesRequestOptions';
import { IQueryOptions, IExecutionOptions, IQueryContext, IQueryContextCulture, IBatchPromiseElement, IBatchOptions, IFundManager, IStatistics } from '../../../models/commonTypes';
import { IConfigurationComponentOrchestration } from '../../../models/app/IConfigurationComponentOrchestration';
const Query = query.Query;
export class QueryStore extends QueryStoreBase {
	storeContext: StoreContext;
	@observable.ref context: IQueryContext | undefined = undefined;
	@observable.ref fundManagerContext: IFundManager | undefined = undefined;
	@observable.ref responseContext: object | undefined = undefined;
	@observable.ref statisticContext: IStatistics | undefined = undefined;

	constructor(storeContext: StoreContext) {
		super();
		this.storeContext = storeContext;
	}

	@computed
	get client(): IQueryContextClient {
		const { kurtosysApiStore } = this.storeContext;
		return kurtosysApiStore.client;
	}

	get inputs() {
		const { appStore } = this.storeContext;
		const { appParamsHelper } = appStore;
		return appParamsHelper.inputs;
	}

	@computed
	get executionOptions(): IExecutionOptions {
		const { appStore, translationStore } = this.storeContext;
		return {
			applicationGuid: appStore.applicationGuid,
			defaultContextParentKey: this.defaultContextParentKey,
			context: this.context,
			responseContext: this.responseContext,
			fundManagerContext: this.fundManagerContext,
			statisticContext: this.statisticContext,
			culture: appStore.getInput('culture'),
			fallbackCulture: appStore.configuration && appStore.configuration.culture && appStore.configuration.culture.default,
			translate: translationStore.translate,
			inputs: this.inputs,
			serviceUrl: appStore.serviceUrl,
			globalFormatsByKey: appStore.globalFormatsByKey,
		};
	}
	get defaultContextParentKey(): string {
		const { appStore } = this.storeContext;
		const { configuration, storeKey } = appStore;
		let response: string = storeKey;
		if (configuration) {
			const { data } = configuration;
			if (data) {
				const { key } = data;
				if (key) {
					response = key;
				}
			}
		}
		return response;
	}
	@action async initialize() {
		const { appStore, kurtosysApiStore } = this.storeContext;
		const { configuration, appParamsHelper, dataContextSeed } = appStore;
		if (configuration && configuration.data && configuration.data.context) {
			const contexts = Array.isArray(configuration.data.context) ? configuration.data.context : [configuration.data.context];
			const batchPromises: IBatchPromiseElement[] = [];
			const inputs: IInputs | undefined = appParamsHelper.inputs;
			const queryContextCulture: IQueryContextCulture = {
				sourceCulture: (configuration && configuration.culture && configuration.culture.base) || 'en-GB',
				targetCulture: (inputs && inputs.culture) || (configuration && configuration.culture && configuration.culture.default) || 'en-GB',
			};
			let contextCounter = 0;
			for (const context of contexts) {
				const queryContext = new QueryContext(context, dataContextSeed);
				const defaultContextKey = `default${ contextCounter > 0 ? contextCounter + 1 : '' }`;
				if (!context.contextKey) {
					context.contextKey = defaultContextKey;
				}
				batchPromises.push({
					identifier: {
						contextParentKey: configuration.data.key || this.defaultContextParentKey,
						contextKey: context.contextKey,
					},
					promiseFunc: () => queryContext.fetch(kurtosysApiStore.client, inputs || {}, queryContextCulture),
					responseFunc: (response: any, identifier: any) => {
						query.Query.contextStore.set({
							...identifier,
							value: {
								context: response,
								contextConfiguration: context,
							},
						});
					},
				});
				contextCounter++;
			}
			const batchOptions: IBatchOptions = {
				maxBatchSize: 10,
			};
			await utils.promise.batchExecutePromises(batchPromises, batchOptions);
		}
	}
	query(options?: IQueryOptions, overrideExecutionOptions?: Partial<IExecutionOptions>) {
		if (options) {
			const { translationStore } = this.storeContext;
			const translationHelper = translationStore && translationStore.translationHelper;
			const query = new Query(options, translationHelper);
			const workingExecutionOptions = query.mergeExecutionOptions(this.executionOptions, overrideExecutionOptions || {});
			const queryResponse = query.execute(workingExecutionOptions);
			return queryResponse;
		}
	}
	async entitiesRequest(id: string, inputs: object, contextParentKey: string = this.defaultContextParentKey, contextKey: string = 'default', options?: IEntitiesRequestOptions) {
		const contextAndConfiguration = query.Query.contextStore.get({ contextParentKey, contextKey });
		console.log({ contextAndConfiguration });
		const { contextConfiguration } = contextAndConfiguration;
		if (contextConfiguration) {
			const { appStore } = this.storeContext;
			const { dataContextSeed } = appStore;
			const queryContext = new QueryContext(contextConfiguration, dataContextSeed);
			if (queryContext && contextConfiguration) {
				let fetchEntitiesOptions;
				const executionOptions = this.executionOptions;
				if (options) {
					fetchEntitiesOptions = options.fetchEntitiesOptions;
					if (options.overrideDynamicInputs === true) {
						Object.assign(executionOptions.inputs, inputs);
					}
				}
				return await queryContext.fetchEntities(this.client, id, inputs, contextConfiguration, executionOptions, fetchEntitiesOptions);
			}
		}
	}

	mergeQueriesAndProps<T>(componentOrchestration?: IConfigurationComponentOrchestration, responseContext?: any): T | undefined {
		if (componentOrchestration) {
			const { translationStore } = this.storeContext;
			const { props, queries } = componentOrchestration;
			const translationHelper = translationStore && translationStore.translationHelper;
			let workingExecutionOptions = this.executionOptions;
			if (responseContext) {
				workingExecutionOptions = {
					...workingExecutionOptions,
					responseContext,
				};
			}
			const queryResults = queries ? query.Query.convertQueryMime(queries, workingExecutionOptions, { translationHelper }) : {};
			const response: T = utils.object.deepMergeObjectsWithOptions({ arrayMergeStrategy: 'DeepMerge' }, props || {}, queryResults);
			return response;
		}
	}
}