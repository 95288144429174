import * as FileSaver from 'file-saver';
import { getExtension } from 'mime';
import { action } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { StoreContext } from '../../../configuration/StoreContext';
import { TDocumentClick } from '@kurtosys/ksys-app-components/dist/components/file/DocumentTile/models';
import { IDocument } from '../../../models/commonTypes';

export class DocumentStore extends StoreBase {
	constructor(storeContext: StoreContext) {
		super(storeContext);
	}

	@action
	async initialize(): Promise<void> {

	}

	saveDocument: TDocumentClick = async (payload: IDocument, filename: string = 'No filename') => {
		try {
			const result: any = await this.getDocument(payload.clientCode);
			this.saveFile(result, filename);
		}
		catch (e) {
			console.warn(e);
		}
	}

	saveFile = (blob: any, filename: string) => {
		const extension = getExtension(blob.type);
		FileSaver.saveAs(
			blob,
			extension ? `${ filename }.${ extension }` : filename);
	}

	getDocument = async (clientCode: string) => {
		if (clientCode) {
			const { kurtosysApiStore } = this.storeContext;
			const overrideOptions = {
				disableCachableRequests: true,
				queryString: { clientCode },
			};
			return await kurtosysApiStore.retrieveDocument.execute(overrideOptions);
		}
		return;
	}
}