import * as FileSaver from 'file-saver';
import { getExtension } from 'mime';
import { computed, action } from 'mobx';
import { FundCardStoreBase } from '../../../common/FundCardStoreBase';
import { IDocumentDownloadConfiguration } from '../models';
import { IDocumentDownloadProps } from '@kurtosys/ksys-app-components/dist/components/file/DocumentDownload/models';
import { IDocumentDownloadItem } from '@kurtosys/ksys-app-components/dist/components/file/DocumentDownload/models/IDocumentDownloadItem';
import { IQueryOptions, IDocument } from '../../../models/commonTypes';

export class DocumentDownloadStore extends FundCardStoreBase {
	static componentKey: 'documentDownload' = 'documentDownload';

	@computed
	get configuration(): IDocumentDownloadConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(DocumentDownloadStore.componentKey);
		}
	}

	@action
	async initialize(): Promise<void> {

	}

	@computed
	get props(): IDocumentDownloadProps | undefined {
		return this.configuration && this.configuration.props;
	}

	@computed
	get labelQueryOptions(): IQueryOptions | undefined {
		return this.configuration && this.configuration.labelQuery;
	}

	@computed
	get documentsQuery(): IQueryOptions | undefined {
		return this.configuration && this.configuration.documentsQuery;
	}

	@computed
	get documents(): IDocumentDownloadItem[] | undefined {
		const queryOptions = this.documentsQuery;
		if (queryOptions) {
			const rawDocuments = this.getQueryValue(queryOptions);

			const documents = rawDocuments.map((document: IDocument) => {
				const { clientCode } = document;
				const parsedLabel = this.labelQueryOptions ? this.getQueryValue(this.labelQueryOptions, document) : document.title;
				return {
					id: clientCode,
					label: parsedLabel,
					payload: document,
				};
			});
			return documents;
		}
	}

	@computed
	get availableLabel(): string | undefined {
		const queryOptions = this.configuration && this.configuration.availableLabelQuery;
		if (queryOptions) {
			return this.getQueryValue(queryOptions);
		}
	}

	@computed
	get documentDownloadProps(): IDocumentDownloadProps {
		const result: IDocumentDownloadProps = {
			...(this.props || {}),
			documents: this.documents,
			availableLabel: this.availableLabel,
			onDownloadClick: this.onClick,
		};
		return result;
	}

	@computed
	get fileNameQuery(): IQueryOptions | undefined {
		return this.configuration && this.configuration.fileNameQuery;
	}

	onClick = async (payload: any): Promise<boolean> => {

		await this.saveDocument(payload, this.fileNameQuery);
		return true;
	}

	saveDocument = async (payload: any, filename?: IQueryOptions) => {
		try {
			const result: any = await this.getDocument(payload.clientCode);
			const parsedFilename = filename ? this.getQueryValue(filename, payload) : 'NO NAME';

			this.saveFile(result, parsedFilename);
		}
		catch (e) {
			console.warn(e);
		}
	}

	saveFile = (blob: any, filename: string) => {
		const extension = getExtension(blob.type);

		FileSaver.saveAs(
			blob,
			extension ? `${ filename }.${ extension }` : filename);
	}

	getDocument = async (clientCode: string) => {
		if (clientCode) {
			const { kurtosysApiStore } = this.storeContext;
			const overrideOptions = {
				disableCachableRequests: true,
				queryString: { clientCode },
			};
			return await kurtosysApiStore.retrieveDocument.execute(overrideOptions);
		}
		return;
	}
}