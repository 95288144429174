import React, { Component } from 'react';
import {
	IChildDropdownProps,
	IChildDropdownState,
} from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import Dropdown from '@kurtosys/ksys-app-components/dist/components/base/Dropdown';

@inject('appStore', 'childDropdownStore')
@observer
export class ChildDropdown extends Component<IChildDropdownProps, IChildDropdownState> {
	static configurationKey: 'childDropdown' = 'childDropdown';
	static styleKey: 'childDropdown' = 'childDropdown';
	constructor(props: IChildDropdownProps) {
		super(props);
	}
	render() {
		const { className, childDropdownStore } = this.props;

		if (!childDropdownStore) {
			return null;
		}

		const { dropdownProps } = childDropdownStore;

		return (
			<Wrapper className={ className }>
				<Dropdown { ...dropdownProps } />
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(ChildDropdown, 'childDropdown');

