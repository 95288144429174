import { IConfiguration } from '../models/app/IConfiguration';

export const CONFIGURATION: IConfiguration = {
	token: 'f09e99df-de0c-456c-ac04-ceb6b39a005c',
	culture: {
		base: 'Default',
		default: 'en-GB',
	},
	core: {
		skeletonLoaders: {
			variables: [
				{
					key: 'fillColor',
					value: '#dddddd',
				},
			],
			loaders: [
				{
					id: 'ksys-app-fund-cards',
					breakpoints: [
						{
							minWidthInPixels: 0,
							style: {
								height: '450px',
							},
							children: [
								{
									'fill': '{fillColor}',
									'x': '50%',
									'y': '11.90625px',
									'height': '28px',
									'width': '25%',
								},
								{
									'fill': '{fillColor}',
									'x': '0px',
									'y': '65px',
									'height': '100%',
									'width': '100%',
								},
							],
						},
					],
				},
			],
		},
	},
	data: {
		entityMapping: {
			rootQueryId: 'funds',
			rootDetailQueryId: 'fundDetail',
			rootEntityType: 'FUND',
			childQueryId: 'primaryClass',
			childDetailQueryId: 'classDetail',
			childEntityType: 'CLSS',
		},
		context: {
			entities: [
				{
					id: 'funds',
					requestBody: {
						type: 'FUND',
						includeProperties: ['fund_code', 'fund_name'],
						include: {
							commentaries: {
								limitTo: ['Fund Commentary'],
							},
						},
						search: [
							{
								matchtype: 'MATCH',
								property: 'is_active',
								values: ['yes'],
							},
						],
					},
				},
				{
					id: 'fundDetail',
					requestBody: {
						type: 'FUND',
						search: [
							{
								matchtype: 'MATCH',
								property: 'is_active',
								values: ['yes'],
							},
						],
						include: {
							commentaries: {
								limitTo: ['Fund Commentary'],
							},
						},
						limit: 1,
					},
				},
				{
					id: 'primaryClass',
					requestBody: {
						type: 'CLSS',
						includeProperties: ['isin', 'fund_code', 'morningstar_rating'],
						search: [],
						limit: 1,
					},
					requestBodyQueries: {
						search: [
							{
								matchtype: 'MATCH',
								property: {
									queryOptionsType: 'none',
									value: 'is_active',
								},
								values: {
									queryOptionsType: 'none',
									value: ['yes'],
								},
							},
							{
								matchtype: 'MATCH',
								property: {
									queryOptionsType: 'none',
									value: 'is_primary',
								},
								values: {
									queryOptionsType: 'none',
									value: ['true'],
								},
							},
							{
								matchtype: 'MATCH',
								property: {
									queryOptionsType: 'none',
									value: 'fund_code',
								},
								values: {
									code: 'fund_code',
									entityType: 'FUND',
									queryOptionsType: 'property',
									transforms: [
										{
											splitSeparator: ',',
											transformOptionsType: 'split',
										},
									],
								},
							},
						],
					},
				},
				{
					id: 'classDetail',
					requestBody: {
						type: 'CLSS',
						search: [
							{
								matchtype: 'MATCH',
								property: 'is_active',
								values: ['yes'],
							},
						],
						include: {
							commentaries: {},
							statistics: {},
						},
					},
				},
			],
		},
	},
	components: {
		app: {
			headingProps: {
				title: 'Fund Card Accordion',
			},
			mode: 'accordion',
			accordionProps: {
				collapsedGridComponentNames: ['title', 'preCommentary', 'keyFact1', 'keyFact2'],
				expandedGridComponentNames: ['title', 'commentary', 'keyFact1', 'keyFact2', 'lineChart'],
			},
			loadMoreOptions: {
				initialCardCount: 3,
				limitInitialApiRequest: true,
			},
		},
		fundCard: {
			embedInputs: [
				{
					key: 'clientCode',
					value: {
						queryOptionsType: 'property',
						entityType: 'CLSS',
						code: 'isin',
					},
				},
			],
			gridProps: {
				breakpoints: [
					{
						size: 'medium',
						modifier: 'up',
						props: {
							grid: {
								rows: [
									{
										columns: [
											{
												components: [
													{
														key: 'components.toggleButton',
													},
												],
											},
											{
												components: [
													{
														name: 'lineChart',
														key: 'base.applicationEmbed',
														props: {
															hostname: 'https://api-rel.fundpress.io',
															templateId: 'ksys-app-line-chart',
															applicationCode: '8e22b789-d37a-44d3-a73e-655b6cc9ebe1',
														},
													},
												],
											},
											{
												components: [
													{
														grid: {
															rows: [
																{
																	columns: [
																		{
																			components: [
																				{
																					key: 'base.heading',
																					name: 'title',
																					props: {
																						level: 2,
																					},
																					queries: {
																						title: {
																							queryOptionsType: 'property',
																							entityType: 'FUND',
																							code: 'fund_name',
																						},
																					},
																				},
																				{
																					key: 'base.text',
																					name: 'preCommentary',
																					props: {
																						hasHtmlContent: true,
																						toggleState: 'collapsed',
																						collapse: {
																							maxLength: 100,
																							suffix: '...',
																						},
																					},
																					queries: {
																						value: {
																							queryOptionsType: 'commentaries',
																							options: {
																								commentaries: {
																									entityType: 'FUND',
																									key: 'Fund Commentary',
																								},
																							},
																						},
																					},
																				},
																				{
																					key: 'base.text',
																					name: 'commentary',
																					props: {
																						hasHtmlContent: true,
																					},
																					queries: {
																						value: {
																							queryOptionsType: 'commentaries',
																							options: {
																								commentaries: {
																									entityType: 'FUND',
																									key: 'Fund Commentary',
																								},
																							},
																						},
																					},
																				},
																			],
																		},
																		{
																			components: [
																				{
																					key: 'components.summaryBlocks',
																					name: 'keyFact1',
																				},
																			],
																		},
																	],
																},
															],
														},
													},
												],
											},
										],
									},
								],
							},
						},
					},
				],
			},
		},
		summaryBlocks: {
			summaryBlocksProps: {
				props: {
					itemsPerRow: 2,
				},
				queries: {
					blocks: [
						{
							title: {
								queryOptionsType: 'none',
								value: 'Morningstar Rating',
							},
							dataType: {
								queryOptionsType: 'none',
								value: 'morningstar-rating',
							},
							morningstarRatingProps: {
								morningStarRating: {
									queryOptionsType: 'property',
									entityType: 'CLSS',
									code: 'morningstar_rating',
								},
							},
						},
						{
							title: {
								queryOptionsType: 'none',
								value: 'ISIN',
							},
							value: {
								queryOptionsType: 'property',
								entityType: 'CLSS',
								code: 'isin',
							},
						},
					],
				},
			},
		},
	},
};
