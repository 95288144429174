import { action, computed, observable } from 'mobx';
import { IGridProps } from '@kurtosys/ksys-app-components/dist/components/base/Grid/models';
import { IGridComponentReference } from '@kurtosys/ksys-app-components/dist/components/base/Grid/models/IGridComponentReference';
import { IFundCardConfiguration } from '../models';
import { QueryStore } from '../../App/stores/QueryStore';
import { StoreBase } from '../../../common/StoreBase';
import { FundCardStoreBase } from '../../../common/FundCardStoreBase';
import { IQueryContext } from '../../../models/commonTypes';
import { StoreContext } from '../../../configuration/StoreContext';
import { IGridComponent } from '@kurtosys/ksys-app-components/dist/components/base/Grid/models/IGridComponent';
import { IGrid } from '@kurtosys/ksys-app-components/dist/components/base/Grid/models/IGrid';
import { deepMergeObjectsWithOptions } from '@kurtosys/ksys-app-template/dist/utils/object';
import { IAppAccordionProps } from '../../App/models/IAppAccordionProps';
import { IApplicationEmbedInput } from '@kurtosys/ksys-app-components/dist/components/base/ApplicationEmbed/models';
import { IInputs } from '../../../models/app/IInputs';

export abstract class BaseCardStore extends StoreBase {
	static componentKey: 'fundCard' = 'fundCard';
	queryStore: QueryStore;
	cardIndex: number;
	@observable expanded = false;
	@observable loading = false;

	constructor(storeContext: StoreContext, cardIndex: number) {
		super(storeContext);
		this.queryStore = new QueryStore(storeContext);
		this.cardIndex = cardIndex;
		// Subscribe card store to app store to allow for single accordion expansion
		const { appStore } = storeContext;
		if (appStore) {
			appStore.subscribeAccordionCardToggle(cardIndex, this.toggleCardExpand);
		}
	}

	abstract get id(): string | undefined;
	abstract get stores(): { [prop: string]: FundCardStoreBase };
	abstract get components(): { [key: string]: IGridComponentReference; };
	abstract loadQueryStoreContext(queryContext?: IQueryContext): void;

	@computed
	get expandedAndLoaded(): boolean {
		return this.expanded && !this.loading;
	}

	@computed
	get collapsed(): boolean {
		return !this.expanded;
	}

	@computed
	get configuration(): IFundCardConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(BaseCardStore.componentKey);
		}
	}

	@computed
	get gridProps(): IGridProps | undefined {
		const { appStore } = this.storeContext;
		const gridProps = this.configuration && this.configuration.gridProps;
		return appStore && appStore.mode === 'accordion' ? this.filterAccordionGridComponents(gridProps) : gridProps;
	}

	@computed
	get accordionProps(): IAppAccordionProps | undefined {
		const { appStore } = this.storeContext;
		if (appStore) {
			return appStore.accordionProps;
		}
	}

	@computed
	get nameCollapsedWhitelist(): string[] {
		if (this.accordionProps) {
			const { collapsedGridComponentNames } = this.accordionProps;
			if (collapsedGridComponentNames) {
				return collapsedGridComponentNames;
			}
		}
		return [];
	}

	@computed
	get nameExpandedWhitelist(): string[] {
		if (this.accordionProps) {
			const { expandedGridComponentNames } = this.accordionProps;
			if (expandedGridComponentNames) {
				return expandedGridComponentNames;
			}
		}
		return [];
	}

	@action
	async fetchDetailContext(): Promise<void> {
		// override this method, to fire additional requests to load additional data into context when an accordion is expanded.
	}

	@action
	toggleLoading = (explicit?: boolean) => {
		this.loading = explicit !== undefined ? explicit : !this.loading;
	}

	@action
	toggleExpand = (explicit?: boolean) => {
		const { appStore } = this.storeContext;
		if (appStore) {
			appStore.toggleAccordionCard(this.cardIndex, explicit);
		}
	}

	@action
	toggleCardExpand = (explicit?: boolean): boolean => {
		const { appStore } = this.storeContext;
		const targetExpandState = explicit !== undefined ? explicit : !this.expanded;
		if (targetExpandState && appStore && appStore.mode === 'accordion') {
			this.fetchDetailContext().then(() => {
				this.expanded = targetExpandState;
			});
		}
		else {
			this.expanded = targetExpandState;
		}
		return targetExpandState;
	}

	@computed
	get appEmbedInputs(): IApplicationEmbedInput[] {
		const { appStore } = this.storeContext;
		const inputs: Partial<IInputs> = appStore && appStore.appParamsHelper.inputs || {};
		return (Object.keys(inputs) as (keyof IInputs)[]).map((key) => {
			const value = inputs[key] as any;
			return {
				key,
				value,
			};
		});
	}

	get embedInputs(): IApplicationEmbedInput[] {
		const inputs: IApplicationEmbedInput[] = this.appEmbedInputs;
		const embedInputsResults = this.mergeQueriesAndProps<IApplicationEmbedInput[]>({ queries: this.configuration && this.configuration.embedInputs });
		if (!embedInputsResults) {
			return this.appEmbedInputs;
		}

		const embedInputIndexes = Object.keys(embedInputsResults);
		for (const index of embedInputIndexes) {
			const embedInput = embedInputsResults[parseInt(index, 10)];
			const replaceIndex = inputs.findIndex((input => input.key === embedInput.key));
			if (replaceIndex >= 0) {
				inputs[replaceIndex].value = embedInput.value;
			}
			else {
				inputs.push(embedInput);
			}
		}

		return inputs;
	}

	filterAccordionGridComponents(gridProps: IGridProps | undefined) {
		if (gridProps && this.accordionProps) {
			const clonedGridProps = deepMergeObjectsWithOptions({ arrayMergeStrategy: 'DeepCopy' }, {}, gridProps) as IGridProps;
			return this.filterGridPropsComponents(clonedGridProps);
		}
		return gridProps;
	}

	filterGridPropsComponents(gridProps: IGridProps): IGridProps {
		if (gridProps) {
			const { breakpoints } = gridProps;
			if (breakpoints && breakpoints.length > 0) {
				for (const breakpoint of breakpoints) {
					if (breakpoint && breakpoint.props) {
						breakpoint.props = this.filterGridPropsComponents(breakpoint.props);
					}
				}
			}

			if (gridProps.grid) {
				gridProps.grid = this.filterGridComponents(gridProps.grid);
			}
		}
		return gridProps;
	}

	filterGridComponents(grid: IGrid | undefined) {
		const { appStore } = this.storeContext;
		const toggleButtonKey = 'components.toggleButton';
		if (grid && grid.rows) {
			for (const row of grid.rows) {
				if (row.columns) {
					for (const col of row.columns) {
						if (col.components) {
							col.components = col.components.reduce<IGridComponent<any>[]>((components, component) => {
								let valid = false;
								if (component.grid) {
									valid = true;
									component.grid = this.filterGridComponents(component.grid);
								}
								else if (component.key && component.key === toggleButtonKey) {
									valid = true;
								}
								else if (this.expandedAndLoaded) {
									if (this.nameExpandedWhitelist.length <= 0 || (component.name && this.nameExpandedWhitelist.indexOf(component.name) >= 0)) {
										valid = true;
									}
								}
								else if (component.name && this.nameCollapsedWhitelist.indexOf(component.name) >= 0) {
									valid = true;
								}
								if (valid) {
									if (appStore && component.key === 'base.applicationEmbed') {
										component.props = appStore.orchestrateEmbed(component.props, 'ksys-app-other', this.embedInputs);
									}
									components.push(component);
								}
								return components;
							}, []);
						}
					}
					// row.columns = row.columns.filter(col => col.components.length > 0);
				}
			}
			// grid.rows = grid.rows.filter(row => row.columns.length > 0);
		}
		return grid;
	}
}