import { IToggleButtonProps } from '@kurtosys/ksys-app-components/dist/components/base/ToggleButton/models';
import { computed, action } from 'mobx';
import { IToggleButtonConfiguration } from '../models';
import { FundCardStoreBase } from '../../../common/FundCardStoreBase';
import { utils } from '@kurtosys/ksys-app-template';

export class ToggleButtonStore extends FundCardStoreBase {
	static componentKey: 'toggleButton' = 'toggleButton';

	@computed
	get configuration(): IToggleButtonConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(ToggleButtonStore.componentKey);
		}
	}

	get toggleButtonProps(): IToggleButtonProps {
		const defaultButtonProps: IToggleButtonProps = {
			onChange: this.onChange,
			loading: this.cardStore.loading,
		};
		const props: IToggleButtonProps = this.mergeQueriesAndProps(this.configuration) || {};
		return utils.object.deepMergeObjectsWithOptions({ arrayMergeStrategy: 'DeepMerge' }, defaultButtonProps, props);
	}

	@action
	async initialize(): Promise<void> {

	}

	@action
	onChange = async (active: boolean): Promise<void> => {
		this.cardStore.toggleExpand(active);
	}
}