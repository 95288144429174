import { AppStoreBase } from './AppStoreBase';
import { IAppComponents } from '../models/IAppComponents';
import { computed, observable, action } from 'mobx';
import { StoreContext } from '../../../configuration/StoreContext';
import { Manifest } from '../../../configuration/Manifest';
import { IEntity, IBatchPromiseElement } from '../../../models/commonTypes';
import { utils } from '@kurtosys/ksys-app-template';
import { QueryStore } from './QueryStore';
import { ICarouselProps } from '@kurtosys/ksys-app-components/dist/components/base/Carousel/models';
import { IHeadingProps } from '@kurtosys/ksys-app-components/dist/components/base/Heading/models';
import { IRedirectButtonProps } from '@kurtosys/ksys-app-components/dist/components/base/RedirectButton/models';
import { IFundCardProps } from '../../FundCard/models';
import { FundCardStore } from '../../FundCard/stores/FundCardStore';
import { IConfigurationDataEntityMapping } from '../../../models/app/IConfigurationDataEntityMapping';
import { IConfigurationData } from '../../../models/app/IConfigurationData';
import { IGridDisplayProps } from '../models/IGridDisplayProps';
import { IConfigurationDataSource } from '../../../models/app/IConfigurationDataSource';
import { DataCardStore } from '../../FundCard/stores/DataCardStore';
import { IAppAccordionProps } from '../models/IAppAccordionProps';
import { TAppMode } from '../models/TAppMode';
import { TToggleCardFn } from '../models/TToggleCardFn';
import { IEntitiesRequestOptions } from '../../../models/app/IEntitiesRequestOptions';
import { ILoadMoreOptions } from '../models/ILoadMoreOptions';

/* [Component: appStoreComponentImport] */

interface IGetEntityDetailOptions {
	entity: IEntity;
	queryId: string;
	queryStore: QueryStore;
}

export class AppStore extends AppStoreBase {
	accordionCardsToggle: (null | TToggleCardFn)[] = [];
	@observable.ref dataForCards: IEntity[] | object[] = [];
	@observable.ref childEntitiesByParentClientCode: { [key: string]: IEntity[] } = {};
	@observable showAllCards = false;
	@observable loadingAllCards = false;

	constructor(element: HTMLElement, url: string, storeContext: StoreContext, manifest: Manifest) {
		super(element, url, storeContext, manifest);
	}

	async customInitializeAfter() {
		if (this.dataSourceType === 'entities') {
			await this.getEntitiesForCards();
		}
		else {
			await this.getEntityTypeDataForCards();
		}
		// initialize all accordion cards as collapsed
		if (this.mode === 'accordion') {
			this.accordionCardsToggle = Array.from(this.dataForCards, () => null);
		}
	}

	subscribeAccordionCardToggle(index: number, cardToggleFn: TToggleCardFn) {
		this.accordionCardsToggle[index] = cardToggleFn;
	}

	@action
	toggleAccordionCard = (index: number, explicit?: boolean) => {
		const currentCardToggle = this.accordionCardsToggle[index];
		if (currentCardToggle) {
			const currentCardState = currentCardToggle(explicit);
			if (currentCardState) {
				const showMultiple = this.accordionProps && this.accordionProps.showMultiple !== undefined ? this.accordionProps.showMultiple : true;
				if (!showMultiple) {
					for (let i = 0; i <= this.accordionCardsToggle.length; i++) {
						if (i !== index) {
							const accordionCardToggle = this.accordionCardsToggle[i];
							if (accordionCardToggle) {
								accordionCardToggle(false);
							}
						}
					}
				}
			}
		}
	}

	@action
	toggleLoadingAllCards = (explicit?: boolean) => {
		this.loadingAllCards = explicit !== undefined ? explicit : !this.loadingAllCards;
	}

	@action
	toggleShowAllCards = () => {
		this.showAllCards = !this.showAllCards;
		if (this.dataSourceType !== 'entityByType') {
			this.toggleLoadingAllCards(true);
			this.getEntitiesForCards().then(() => {
				this.toggleLoadingAllCards(false);
			});
		}
	}

	@action
	async getEntityTypeDataForCards(): Promise<void> {
		const source = this.mergeQueriesAndProps<{ data: any }>({ queries: { data: this.dataSource } });
		if (source && source.data) {
			this.dataForCards = source.data;
		}
	}

	@action
	async getEntitiesForCards(): Promise<void> {
		this.dataForCards = [];
		const { queryStore } = this.storeContext;
		const { inputs } = this.appParamsHelper;
		if (inputs) {
			let limitOptions: IEntitiesRequestOptions = {};
			if (this.limitEntitiesOnLoad && !this.showAllCards) {
				limitOptions = {
					fetchEntitiesOptions: {
						staticEntityOverride: {
							requestBody: {
								start: 0,
								limit: this.initialLoadMoreCount,
							},
						},
					},
				};
			}
			const response = await queryStore.entitiesRequest(this.entityMapping.rootQueryId, inputs, undefined, undefined, limitOptions);
			if (response && response.values) {
				const childEntitiesByParentClientCode: { [key: string]: IEntity[] } = {};
				const batchPromises: IBatchPromiseElement[] = [];
				for (const entity of response.values) {

					const { clientCode } = entity;
					const batchPromise: IBatchPromiseElement = {
						identifier: clientCode,
						promiseFunc: () => this.getChildEntities(entity),
						responseFunc: (childEntities: IEntity[], identifier: string) => {
							if (childEntities && childEntities.length > 0) {
								childEntitiesByParentClientCode[identifier] = childEntities;
							}
						},
					};
					batchPromises.push(batchPromise);
				}
				await utils.promise.batchExecutePromises(batchPromises);
				this.dataForCards = response.values;
				this.childEntitiesByParentClientCode = childEntitiesByParentClientCode;
			}
		}
	}

	@action
	async getChildEntities(entity: IEntity): Promise<IEntity[]> {
		let childEntities: IEntity[] = [];
		const entityMapping = this.entityMapping;
		if (entityMapping && entityMapping.childQueryId && entityMapping.childEntityType) {
			const { rootEntityType, childQueryId } = entityMapping;
			const queryStore = new QueryStore(this.storeContext);
			queryStore.context = {
				entityByType: {
					[rootEntityType]: entity,
				},
			};
			const { appStore } = this.storeContext;
			const { appParamsHelper } = appStore;
			const { inputs } = appParamsHelper;
			if (inputs) {
				const response = await queryStore.entitiesRequest(childQueryId, inputs);
				if (response && response.values) {
					childEntities = response.values;
				}
			}
		}
		return childEntities;
	}

	@action
	async getRootEntityDetailForCard(entity: IEntity): Promise<IEntity | undefined> {
		const entityMapping = this.entityMapping;
		if (entityMapping && entityMapping.rootEntityType && entityMapping.rootDetailQueryId) {
			const options: IGetEntityDetailOptions = {
				entity,
				queryStore: this.storeContext.queryStore,
				queryId: entityMapping.rootDetailQueryId,
			};
			return await this.getEntityDetailForCard(options);
		}
	}

	@action
	async getChildEntityDetailForCard(parentEntity: IEntity, entity: IEntity): Promise<IEntity | undefined> {
		const entityMapping = this.entityMapping;
		if (entityMapping) {
			const { rootEntityType, childEntityType, childDetailQueryId } = entityMapping;
			if (rootEntityType && childEntityType && childDetailQueryId) {
				const queryStore = new QueryStore(this.storeContext);
				queryStore.context = {
					entityByType: {
						[rootEntityType]: parentEntity,
					},
				};
				const options: IGetEntityDetailOptions = {
					entity,
					queryStore,
					queryId: childDetailQueryId,
				};
				return await this.getEntityDetailForCard(options);
			}
		}
	}

	@action
	async getEntityDetailForCard(options: IGetEntityDetailOptions): Promise<IEntity | undefined> {
		const { queryStore, queryId, entity } = options;
		const { appStore } = this.storeContext;
		const { appParamsHelper } = appStore;
		const { inputs } = appParamsHelper;
		if (inputs) {
			const response = await queryStore.entitiesRequest(queryId, inputs, undefined, undefined, {
				fetchEntitiesOptions: {
					staticEntityOverride: {
						requestBody: {
							clientCode: [entity.clientCode],
						},
					},
					staticEntityOverrideArrayType: 'replaceValues',
				},
			});
			if (response && response.values && response.values.length > 0) {
				return response.values[0];
			}
		}
	}

	@computed
	get hasData(): boolean {
		if (this.loadingAllCards) {
			return true;
		}
		return Array.isArray(this.gridCards) && this.gridCards.length > 0;
	}

	@computed
	get components(): IAppComponents {
		return {
			/* [Component: appStoreComponent] */
		};
	}

	@computed
	get mode(): TAppMode | undefined {
		const explicitMode = this.appComponentConfiguration && this.appComponentConfiguration.mode;
		if (explicitMode) {
			return explicitMode;
		}
		if (this.accordionProps) {
			return 'accordion';
		}
		if (this.carouselProps) {
			return 'carousel';
		}
		if (this.gridDisplayProps) {
			return 'grid';
		}
	}

	isModeOneOf = (modes: TAppMode[]): boolean => {
		return this.mode !== undefined && (modes).indexOf(this.mode) >= 0;
	}

	@computed
	get accordionProps(): IAppAccordionProps | undefined {
		return this.appComponentConfiguration && this.appComponentConfiguration.accordionProps;
	}

	@computed
	get carouselProps(): ICarouselProps | undefined {
		return this.appComponentConfiguration && this.appComponentConfiguration.carouselProps;
	}

	@computed
	get gridDisplayProps(): IGridDisplayProps | undefined {
		const gridDisplayProps = this.appComponentConfiguration && this.appComponentConfiguration.gridDisplayOptions;
		return this.mergeQueriesAndProps(gridDisplayProps);
	}

	@computed
	get loadMoreOptions(): ILoadMoreOptions | undefined {
		return this.appComponentConfiguration && this.appComponentConfiguration.loadMoreOptions;
	}

	@computed
	get headingProps(): IHeadingProps | undefined {
		return this.appComponentConfiguration && this.appComponentConfiguration.headingProps;
	}

	@computed
	get globalRedirectButtonProps(): IRedirectButtonProps | undefined {
		return this.appComponentConfiguration && this.appComponentConfiguration.globalRedirectButtonProps;
	}

	@computed
	get initialLoadMoreCount(): number {
		if (this.isModeOneOf(['grid', 'accordion'])) {
			const gridInitialCount = this.gridDisplayProps && this.gridDisplayProps.initialCardCount;
			const loadMoreInitialCount = this.loadMoreOptions && this.loadMoreOptions.initialCardCount;
			return loadMoreInitialCount || gridInitialCount || 0;
		}
		return 0;
	}

	@computed
	get isLoadMoreEnabled(): boolean {
		return this.initialLoadMoreCount > 0;
	}

	@computed
	get limitEntitiesOnLoad(): boolean {
		if (this.isLoadMoreEnabled) {
			return this.loadMoreOptions && this.loadMoreOptions.limitInitialApiRequest || false;
		}
		return false;
	}

	@computed
	get gridCards(): IFundCardProps[] {
		const response: IFundCardProps[] = [];
		const showAllCards = !this.isLoadMoreEnabled || this.showAllCards;
		let cardCount = 1;
		for (const cardData of this.dataForCards) {
			if (showAllCards || cardCount <= this.initialLoadMoreCount) {
				let cardStore;
				const cardIndex = cardCount - 1;

				if (this.dataSourceType === 'entityByType') {
					cardStore = new DataCardStore(this.storeContext, cardData as object, cardIndex);
				}
				else {
					const { clientCode } = cardData as IEntity;
					cardStore = new FundCardStore(this.storeContext, cardData as IEntity, this.childEntitiesByParentClientCode[clientCode], cardIndex);
				}

				response.push({
					cardStore,
				});
			}
			cardCount++;
		}
		return response;
	}

	@computed
	get dataSourceType(): IConfigurationDataSource['type'] {
		return this.dataSource && this.dataSource.type || 'entities';
	}

	@computed
	get dataSource(): IConfigurationDataSource | undefined {
		const dataConfiguration = this.dataConfiguration;
		if (dataConfiguration && dataConfiguration.source) {
			return dataConfiguration.source;
		}
		return;
	}

	@computed
	get entityMapping(): IConfigurationDataEntityMapping {
		const dataConfiguration = this.dataConfiguration;
		if (dataConfiguration && dataConfiguration.entityMapping) {
			return dataConfiguration.entityMapping;
		}

		return {
			rootQueryId: 'entitiesForCards',
			rootEntityType: 'FUND',
		};
	}

	@computed
	get dataConfiguration(): IConfigurationData | undefined {
		return this.configuration && this.configuration.data;
	}

}