import React, { Component } from 'react';
import {
	IFactListProps,
	IFactListState,
} from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import FactListComponent from '@kurtosys/ksys-app-components/dist/components/fact/FactList';

@inject('appStore')
@observer
export class FactList extends Component<IFactListProps, IFactListState> {
	static configurationKey: 'factList' = 'factList';
	static styleKey: 'factList' = 'factList';
	constructor(props: IFactListProps) {
		super(props);
	}
	render() {
		const { className, factListStore } = this.props;

		if (!factListStore) {
			return null;
		}
		const { factListProps } = factListStore;
		return (
			<Wrapper className={ className }>
				{ factListProps && <FactListComponent { ...factListProps } /> }
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(FactList, 'factList');

