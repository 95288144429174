import { computed, action } from 'mobx';
import { FundCardStoreBase } from '../../../common/FundCardStoreBase';
import { IFactListConfiguration } from '../models';
import { IFactListProps } from '@kurtosys/ksys-app-components/dist/components/fact/FactList/models';

export class FactListStore extends FundCardStoreBase {
	static componentKey: 'factList' = 'factList';

	@computed
	get configuration(): IFactListConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(FactListStore.componentKey);
		}
	}

	@action
	async initialize(): Promise<void> {

	}

	@computed
	get factListProps(): IFactListProps | undefined {
		const factListProps = this.configuration && this.configuration.factListProps;
		return this.mergeQueriesAndProps(factListProps);
	}
}