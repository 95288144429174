import { computed, action } from 'mobx';
import { FundCardStoreBase } from '../../../common/FundCardStoreBase';
import { ISummaryBlocksConfiguration } from '../models';
import { ISummaryBlocksProps } from '@kurtosys/ksys-app-components/dist/components/fact/SummaryBlocks/models';
import { FundCardStore } from '../../FundCard/stores/FundCardStore';

export class SummaryBlocksStore extends FundCardStoreBase {
	static componentKey: 'summaryBlocks' = 'summaryBlocks';

	@computed
	get configuration(): ISummaryBlocksConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(SummaryBlocksStore.componentKey);
		}
	}

	@action
	async initialize(): Promise<void> {

	}

	@computed
	get summaryBlocksProps(): ISummaryBlocksProps | undefined {
		const summaryBlocksProps = this.configuration && this.configuration.summaryBlocksProps;
		const props = this.mergeQueriesAndProps<ISummaryBlocksProps>(summaryBlocksProps);
		if (props) {
			const { documentStore } = this.storeContext;
			if (this.cardStore instanceof FundCardStore) {
				props.onDocumentClick = documentStore.saveDocument;
			}
		}
		return props;
	}
}