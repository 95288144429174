import { TemplateStore } from '../components/App/stores/TemplateStore';
import { AppStore } from '../components/App/stores/AppStore';
import { KurtosysApiStore } from '../components/App/stores/KurtosysApiStore';
import { TranslationStore } from '../components/App/stores/TranslationStore';
import { QueryStore } from '../components/App/stores/QueryStore';
import { DocumentStore } from '../components/App/stores/DocumentStore';
import { Manifest } from './Manifest';
import { AccessibilityStore } from '../components/App/stores/AccessibilityStore';
/* [Component: storesImport] */

export class StoreContext {
	templateStore: TemplateStore;
	appStore: AppStore;
	kurtosysApiStore: KurtosysApiStore;
	translationStore: TranslationStore;
	queryStore: QueryStore;
	documentStore: DocumentStore;
	accessibilityStore: AccessibilityStore;
	/* [Component: storeVariable] */
	constructor(element: HTMLElement, url: string, templateStore: TemplateStore, public manifest: Manifest) {
		this.templateStore = templateStore;
		this.kurtosysApiStore = new KurtosysApiStore(this);
		this.translationStore = new TranslationStore(this);
		this.queryStore = new QueryStore(this);
		this.documentStore = new DocumentStore(this);
		this.appStore = new AppStore(element, url, this, manifest);
		this.accessibilityStore = new AccessibilityStore(this);
		/* [Component: storeAssignment] */
		this.initialize();
	}
	initialize() {
		this.appStore.initialize();
	}
	reInitialize() {
		this.appStore.reInitialize();
	}
	get providerProps() {
		return {
			appStore: this.appStore,
			kurtosysApiStore: this.kurtosysApiStore,
			translationStore: this.translationStore,
			queryStore: this.queryStore,
			documentStore: this.documentStore,
			accessibilityStore: this.accessibilityStore,
			/* [Component: storeProviderProps] */
		};
	}

	// TODO: NEED TO MERGE STORES
	registerStore(key: string, store: any) {

	}
}