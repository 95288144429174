import { utils, query } from '@kurtosys/ksys-app-template';
import { computed, action, observable } from 'mobx';
import { IGridComponentReference } from '@kurtosys/ksys-app-components/dist/components/base/Grid/models/IGridComponentReference';
import { SummaryBlocksStore } from '../../SummaryBlocks/stores/SummaryBlocksStore';
import { ChildDropdownStore } from '../../ChildDropdown/stores/ChildDropdownStore';
import { FactListStore } from '../../FactList/stores/FactListStore';
import { RedirectButtonStore } from '../../RedirectButton/stores/RedirectButtonStore';
import SummaryBlocks from '../../SummaryBlocks';
import ChildDropdown from '../../ChildDropdown';
import DocumentDownload from '../../DocumentDownload';
import FactList from '../../FactList';
import RedirectButton from '../../RedirectButton';
import DefinitionList from '../../DefinitionList';
import ToggleButton from '../../ToggleButton';
import { StoreContext } from '../../../configuration/StoreContext';
import { IConfigurationDataEntityMapping } from '../../../models/app/IConfigurationDataEntityMapping';
import { IQueryOptions, IExecutionOptions, IEntity } from '../../../models/commonTypes';
import { IConfigurationComponentOrchestration } from '../../../models/app/IConfigurationComponentOrchestration';
import { DocumentDownloadStore } from '../../DocumentDownload/stores/DocumentDownloadStore';
import { DefinitionListStore } from '../../DefinitionList/stores/DefinitionListStore';
import { FundCardStoreBase } from '../../../common/FundCardStoreBase';
import { BaseCardStore } from './BaseCardStore';
import { ToggleButtonStore } from '../../ToggleButton/stores/ToggleButtonStore';

const deepMergeObjects = utils.object.deepMergeObjects;
const Query = query.Query;

export class FundCardStore extends BaseCardStore {
	@observable.ref rawRootEntity: IEntity;
	@observable.ref rootEntityDetail: IEntity | undefined;
	@observable.ref rawChildEntity: IEntity | undefined;
	@observable.ref childEntities: IEntity[] = [];
	@observable.ref rawChildEntityDetail: IEntity | undefined;

	constructor(storeContext: StoreContext, rootEntity: IEntity, childEntities: IEntity[] = [], cardIndex: number) {
		super(storeContext, cardIndex);
		this.rawRootEntity = rootEntity;
		this.childEntities = childEntities;
		this.childEntity = this.getInitialChildEntity();
	}

	@action
	async initialize(): Promise<void> {

	}

	@computed
	get entityMapping(): IConfigurationDataEntityMapping {
		const { appStore } = this.storeContext;
		return appStore.entityMapping;
	}

	loadQueryStoreContext = (): void => {
		const { queryStore } = this.storeContext;
		const entityMapping = this.entityMapping;
		const { rootEntityType, childEntityType } = entityMapping;
		this.queryStore.context = {
			...(queryStore.context || {}),
			entityByType: {
				[rootEntityType]: this.rootEntity,
			},
		};
		if (childEntityType && this.childEntity && this.queryStore.context.entityByType) {
			this.queryStore.context.entityByType[childEntityType] = this.childEntity;
		}
	}

	@computed
	get rootEntity(): IEntity {
		return this.rootEntityDetail || this.rawRootEntity;
	}

	@computed
	get childEntity(): IEntity | undefined {
		return this.childEntityDetail || this.rawChildEntity;
	}

	set childEntity(value: IEntity | undefined) {
		this.rawChildEntity = value;
		this.childEntityDetail = undefined;
		this.loadQueryStoreContext();
	}

	@computed
	get childEntityDetail(): IEntity | undefined {
		return this.rawChildEntityDetail;
	}

	set childEntityDetail(value: IEntity | undefined) {
		this.rawChildEntityDetail = value;
		this.loadQueryStoreContext();
	}

	@action
	getInitialChildEntity(): IEntity | undefined {
		if (this.childEntities && this.childEntities.length > 0) {
			return this.childEntities[0];
		}
	}

	@action
	async fetchDetailContext(): Promise<void> {
		const { appStore } = this.storeContext;
		if (appStore) {
			this.toggleLoading(true);
			try {
				if (this.rawRootEntity && !this.rootEntityDetail) {
					this.rootEntityDetail = await appStore.getRootEntityDetailForCard(this.rawRootEntity);
				}
				if (this.rawChildEntity && !this.childEntityDetail) {
					this.childEntityDetail = await appStore.getChildEntityDetailForCard(this.rawRootEntity, this.rawChildEntity);
				}
			}
			catch (e) {
				console.error(e);
			}
			finally {
				this.toggleLoading(false);
			}
		}
	}

	@computed
	get id(): string | undefined {
		return this.rootEntity && this.rootEntity.clientCode.toLowerCase();
	}

	@computed
	get stores(): { [prop: string]: FundCardStoreBase } {
		return {
			toggleButton: new ToggleButtonStore(this.storeContext, this),
			summaryBlocks: new SummaryBlocksStore(this.storeContext, this),
			childDropdown: new ChildDropdownStore(this.storeContext, this),
			documentDownload: new DocumentDownloadStore(this.storeContext, this),
			factList: new FactListStore(this.storeContext, this),
			redirectButton: new RedirectButtonStore(this.storeContext, this),
			definitionList: new DefinitionListStore(this.storeContext, this),
		};
	}

	@computed
	get components(): { [key: string]: IGridComponentReference; } {
		return {
			'components.toggleButton': {
				key: 'components.toggleButton',
				component: ToggleButton,
				props: {
					toggleButtonStore: this.stores.toggleButton,
				},
			},
			'components.summaryBlocks': {
				key: 'components.summaryBlocks',
				component: SummaryBlocks,
				props: {
					summaryBlocksStore: this.stores.summaryBlocks,
				},
			},
			'components.childDropdown': {
				key: 'components.childDropdown',
				component: ChildDropdown,
				props: {
					childDropdownStore: this.stores.childDropdown,
				},
			},
			'components.definitionList': {
				key: 'components.definitionList',
				component: DefinitionList,
				props: {
					definitionListStore: this.stores.definitionList,
				},
			},
			'components.documentDownload': {
				key: 'components.documentDownload',
				component: DocumentDownload,
				props: {
					documentDownloadStore: this.stores.documentDownload,
				},
			},
			'components.factList': {
				key: 'components.factList',
				component: FactList,
				props: {
					factListStore: this.stores.factList,
				},
			},
			'components.redirectButton': {
				key: 'components.redirectButton',
				component: RedirectButton,
				props: {
					redirectButtonStore: this.stores.redirectButton,
				},
			},
		};
	}

	getQueryValue(queryOptions?: IQueryOptions, responseForContext?: object, overrideExecutionOptions?: Partial<IExecutionOptions>) {
		if (queryOptions) {
			const responseContextExecutionOptions: Partial<IExecutionOptions> = {
				context: {
					response: responseForContext,
				},
			};
			overrideExecutionOptions = deepMergeObjects(
				responseContextExecutionOptions,
				overrideExecutionOptions || {},
			);
			return this.queryStore.query(queryOptions, overrideExecutionOptions);
		}
		return;
	}

	mergeQueriesAndProps<T>(componentOrchestration?: IConfigurationComponentOrchestration): T | undefined {
		if (componentOrchestration) {
			const { translationStore } = this.storeContext;
			const translationHelper = translationStore && translationStore.translationHelper;
			const { props, queries } = componentOrchestration;
			const queryResults = queries ? Query.convertQueryMime(queries, this.queryStore.executionOptions, { translationHelper }) : {};
			const response: T = deepMergeObjects(props || {}, queryResults);
			return response;
		}
	}

}