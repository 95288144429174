import { computed, action } from 'mobx';
import { IGridComponentReference } from '@kurtosys/ksys-app-components/dist/components/base/Grid/models/IGridComponentReference';
import { SummaryBlocksStore } from '../../SummaryBlocks/stores/SummaryBlocksStore';
import { FactListStore } from '../../FactList/stores/FactListStore';
import { RedirectButtonStore } from '../../RedirectButton/stores/RedirectButtonStore';
import SummaryBlocks from '../../SummaryBlocks';
import FactList from '../../FactList';
import RedirectButton from '../../RedirectButton';
import DocumentImage from '../../DocumentImage';
import DefinitionList from '../../DefinitionList';
import ToggleButton from '../../ToggleButton';
import { StoreContext } from '../../../configuration/StoreContext';
import { DefinitionListStore } from '../../DefinitionList/stores/DefinitionListStore';
import { BaseCardStore } from './BaseCardStore';
import { DocumentImageStore } from '../../DocumentImage/stores/DocumentImageStore';
import { ToggleButtonStore } from '../../ToggleButton/stores/ToggleButtonStore';
import { FundCardStoreBase } from '../../../common/FundCardStoreBase';

export class DataCardStore extends BaseCardStore {
	constructor(storeContext: StoreContext, responseObject: object, cardIndex: number) {
		super(storeContext, cardIndex);
		this.loadQueryStoreContext(responseObject);
	}

	loadQueryStoreContext(responseContext: object) {
		const { queryStore } = this.storeContext;
		if (queryStore) {
			this.queryStore.responseContext = {
				...(queryStore.responseContext || {}),
				...(responseContext || {}),
			};
		}
	}

	@action
	async initialize(): Promise<void> { }

	@computed
	get id(): string | undefined {
		return;
	}

	@computed
	get stores(): { [prop: string]: FundCardStoreBase } {
		return {
			toggleButton: new ToggleButtonStore(this.storeContext, this),
			summaryBlocks: new SummaryBlocksStore(this.storeContext, this),
			factList: new FactListStore(this.storeContext, this),
			redirectButton: new RedirectButtonStore(this.storeContext, this),
			definitionList: new DefinitionListStore(this.storeContext, this),
			documentImage: new DocumentImageStore(this.storeContext, this),
		};
	}

	@computed
	get components(): { [key: string]: IGridComponentReference; } {
		return {
			'components.toggleButton': {
				key: 'components.toggleButton',
				component: ToggleButton,
				props: {
					toggleButtonStore: this.stores.toggleButton,
				},
			},
			'components.summaryBlocks': {
				key: 'components.summaryBlocks',
				component: SummaryBlocks,
				props: {
					summaryBlocksStore: this.stores.summaryBlocks,
				},
			},
			'components.definitionList': {
				key: 'components.definitionList',
				component: DefinitionList,
				props: {
					definitionListStore: this.stores.definitionList,
				},
			},
			'components.factList': {
				key: 'components.factList',
				component: FactList,
				props: {
					factListStore: this.stores.factList,
				},
			},
			'components.redirectButton': {
				key: 'components.redirectButton',
				component: RedirectButton,
				props: {
					redirectButtonStore: this.stores.redirectButton,
				},
			},
			'components.documentImage': {
				key: 'components.documentImage',
				component: DocumentImage,
				props: {
					documentImageStore: this.stores.documentImage,
				},
			},
		};
	}
}