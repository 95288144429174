import { StoreBase } from './StoreBase';
import { StoreContext } from '../configuration/StoreContext';
import { action } from 'mobx';
import { IConfigurationComponentOrchestration } from '../models/app/IConfigurationComponentOrchestration';
import { query, utils } from '@kurtosys/ksys-app-template';
import { DataCardStore } from '../components/FundCard/stores/DataCardStore';
import { FundCardStore } from '../components/FundCard/stores/FundCardStore';
import { IExecutionOptions, IQueryOptions } from '../models/commonTypes';

export abstract class FundCardStoreBase extends StoreBase {
	cardStore: FundCardStore | DataCardStore;

	constructor(storeContext: StoreContext, cardStore: FundCardStore | DataCardStore) {
		super(storeContext);
		this.cardStore = cardStore;
	}


	@action
	async initialize(): Promise<void> {

	}

	getQueryValue(queryOptions?: IQueryOptions, responseForContext?: object, overrideExecutionOptions?: Partial<IExecutionOptions>) {
		if (queryOptions) {
			let responseContextExecutionOptions: Partial<IExecutionOptions> = {};
			if (responseForContext) {
				responseContextExecutionOptions = {
					context: {
						response: responseForContext,
					},
				};
			}
			overrideExecutionOptions = utils.object.deepMergeObjects(
				responseContextExecutionOptions,
				overrideExecutionOptions || {},
			);
			return this.cardStore.queryStore.query(queryOptions, overrideExecutionOptions);
		}
		return;
	}


	mergeQueriesAndProps<T>(componentOrchestration?: IConfigurationComponentOrchestration): T | undefined {
		if (componentOrchestration) {
			const { props, queries } = componentOrchestration;
			const queryResults = queries ? query.Query.convertQueryMime(queries, this.cardStore.queryStore.executionOptions) : {};
			const response: T = utils.object.deepMergeObjectsWithOptions({ arrayMergeStrategy: 'DeepMerge' }, props || {}, queryResults);
			return response;
		}
	}
}