import React, { Component } from 'react';
import {
	IToggleButtonProps,
	IToggleButtonState,
} from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import ToggleButtonComponent from '@kurtosys/ksys-app-components/dist/components/base/ToggleButton';

@inject('appStore', 'toggleButtonStore')
@observer
export class ToggleButton extends Component<IToggleButtonProps, IToggleButtonState> {
	static configurationKey: 'toggleButton' = 'toggleButton';
	static styleKey: 'toggleButton' = 'toggleButton';
	constructor(props: IToggleButtonProps) {
		super(props);
	}
	render() {
		const { className, toggleButtonStore } = this.props;

		if (!toggleButtonStore) {
			return null;
		}

		const { toggleButtonProps } = toggleButtonStore;

		if (!toggleButtonProps) {
			return null;
		}

		return (
			<Wrapper data-selector="expand-button" className={ className }>
				<ToggleButtonComponent { ...toggleButtonProps } />
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(ToggleButton, 'toggleButton');

