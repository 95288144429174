import { computed, action } from 'mobx';
import { IChildDropdownConfiguration } from '../models';
import { FundCardStoreBase } from '../../../common/FundCardStoreBase';
import { IDropdownProps } from '@kurtosys/ksys-app-components/dist/components/base/Dropdown/models/IDropdownProps';
import { IDropdownEntityItem } from '../models/IDropdownEntityItem';
import { query } from '@kurtosys/ksys-app-template';
import { IEntity, IExecutionOptions } from '../../../models/commonTypes';
import { FundCardStore } from '../../FundCard/stores/FundCardStore';

const Query = query.Query;

export class ChildDropdownStore extends FundCardStoreBase {
	static componentKey: 'childDropdown' = 'childDropdown';

	@computed
	get configuration(): IChildDropdownConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(ChildDropdownStore.componentKey);
		}
	}

	@action
	async initialize(): Promise<void> {

	}

	@computed
	get childEntities(): IEntity[] {
		if (this.cardStore instanceof FundCardStore) {
			return this.cardStore.childEntities;
		}
		return [];
	}

	@computed
	get items(): IDropdownEntityItem[] {
		return this.childEntities.map(this.convertEntityToDropdownItem);
	}

	@computed
	get selectedItem(): IDropdownEntityItem | undefined {
		const childEntity = this.cardStore instanceof FundCardStore && this.cardStore.childEntity;
		if (childEntity) {
			return this.convertEntityToDropdownItem(childEntity);
		}
	}

	convertEntityToDropdownItem = (entity: IEntity) => {
		const response = {
			entity,
			label: entity.clientCode,
			value: entity.clientCode,
		} as IDropdownEntityItem;
		if (this.configuration) {
			const { label, value } = this.configuration;
			const { context, ...executionOptions } = this.cardStore.queryStore.executionOptions;
			(executionOptions as IExecutionOptions).context = {
				entityByType: {
					[entity.type]: entity,
				},
			};
			if (label) {
				const labelQuery = new Query(label);
				response.label = labelQuery.execute(executionOptions);
			}
			if (value) {
				const valueQuery = new Query(value);
				response.value = valueQuery.execute(executionOptions);
			}
		}
		return response;
	}

	@action
	handleChange = (item: IDropdownEntityItem | IDropdownEntityItem[]) => {
		if (!Array.isArray(item) && this.cardStore instanceof FundCardStore && item && item.entity) {
			const { entity } = item;
			this.cardStore.childEntity = entity;
		}
	}

	@computed
	get dropdownProps(): IDropdownProps {
		const result: IDropdownProps = {
			items: this.items,
			selectedItem: this.selectedItem,
			onChange: this.handleChange,
		};
		return result;
	}
}