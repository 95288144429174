import React, { Component } from 'react';
import { IFundCardProps, IFundCardState } from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import Grid from '@kurtosys/ksys-app-components/dist/components/base/Grid';

@inject('appStore')
@observer
export class FundCard extends Component<IFundCardProps, IFundCardState> {
	static configurationKey: 'fundCard' = 'fundCard';
	static styleKey: 'fundCard' = 'fundCard';
	constructor(props: IFundCardProps) {
		super(props);
	}
	render() {
		const { className, cardStore } = this.props;

		if (!cardStore) {
			return null;
		}

		const { id, gridProps, queryStore, components } = cardStore;

		return (
			<Wrapper className={ className } data-selector="app-card" id={ `app-card-${ id }` }>
				{gridProps && <Grid queryStore={ queryStore } components={ components } noPropFlatten={ true } { ...gridProps } /> }
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(FundCard, 'fundCard');
