import { IComponentConfiguration } from '@kurtosys/ksys-app-components/dist/models/IComponentConfiguration';
import ApplicationEmbed from '@kurtosys/ksys-app-components/dist/components/base/ApplicationEmbed';
import Badge from '@kurtosys/ksys-app-components/dist/components/base/Badge';
import Color from '@kurtosys/ksys-app-components/dist/components/base/Color';
import Heading from '@kurtosys/ksys-app-components/dist/components/base/Heading';
import Image from '@kurtosys/ksys-app-components/dist/components/base/Image';
import Pill from '@kurtosys/ksys-app-components/dist/components/base/Pill';
import Text from '@kurtosys/ksys-app-components/dist/components/base/Text';
import Fact from '@kurtosys/ksys-app-components/dist/components/fact/Fact';
import DocumentDownload from '@kurtosys/ksys-app-components/dist/components/file/DocumentDownload';
import Disclaimer from '@kurtosys/ksys-app-components/dist/components/overview/Disclaimer';

export const LIBRARY_COMPONENTS_CONFIGURATION: IComponentConfiguration = {
	base: {
		grid: {
			components: {
				'base.applicationEmbed': {
					key: 'base.applicationEmbed',
					component: ApplicationEmbed,
				},
				'base.badge': {
					key: 'base.badge',
					component: Badge,
				},
				'base.color': {
					key: 'base.color',
					component: Color,
				},
				'base.heading': {
					key: 'base.heading',
					component: Heading,
				},
				'base.image': {
					key: 'base.image',
					component: Image,
				},
				'base.pill': {
					key: 'base.pill',
					component: Pill,
				},
				'base.text': {
					key: 'base.text',
					component: Text,
				},
				'fact.fact': {
					key: 'fact.fact',
					component: Fact,
				},
				'file.documentDownload': {
					key: 'file.documentDownload',
					component: DocumentDownload,
				},
				'overview.disclaimer': {
					key: 'overview.disclaimer',
					component: Disclaimer,
				},
			},
		},
	},
};