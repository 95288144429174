import React, { Component } from 'react';
import { IDocumentImageProps, IDocumentImageState } from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import ImagePlaceholder from './styledComponents/ImagePlaceholder';
import Image from '@kurtosys/ksys-app-components/dist/components/base/Image';

@inject('appStore', 'documentImageStore')
@observer
export class DocumentImage extends Component<IDocumentImageProps, IDocumentImageState> {
	static configurationKey: 'documentImage' = 'documentImage';
	static styleKey: 'documentImage' = 'documentImage';
	constructor(props: IDocumentImageProps) {
		super(props);
		const { documentImageStore } = props;
		if (documentImageStore) {
			documentImageStore.setImageProps(this.props);
		}
	}
	componentWillUpdate(nextProps: IDocumentImageProps) {
		const { documentImageStore } = nextProps;
		if (documentImageStore) {
			documentImageStore.setImageProps(this.props);
		}
	}
	render() {
		const { className, documentImageStore } = this.props;
		if (!documentImageStore) {
			return null;
		}
		const { getImageProps } = documentImageStore;
		const imageProps = getImageProps(this.props);
		return (
			<Wrapper className={className} data-selector="app-document-image">
				{imageProps && <Image {...imageProps} />}
				{!imageProps && <ImagePlaceholder />}
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(DocumentImage, 'documentImage');
