import React, { Component } from 'react';
import {
	ISummaryBlocksProps,
	ISummaryBlocksState,
} from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import SummaryBlocksComponent from '@kurtosys/ksys-app-components/dist/components/fact/SummaryBlocks';

@inject('appStore')
@observer
export class SummaryBlocks extends Component<ISummaryBlocksProps, ISummaryBlocksState> {
	static configurationKey: 'summaryBlocks' = 'summaryBlocks';
	static styleKey: 'summaryBlocks' = 'summaryBlocks';
	constructor(props: ISummaryBlocksProps) {
		super(props);
	}
	render() {
		const { className, summaryBlocksStore } = this.props;
		if (!summaryBlocksStore) {
			return null;
		}

		const { summaryBlocksProps } = summaryBlocksStore;
		return (
			<Wrapper className={ className }>
				<SummaryBlocksComponent { ...summaryBlocksProps } />
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(SummaryBlocks, 'summaryBlocks');

