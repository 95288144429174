import { computed, action } from 'mobx';
import { FundCardStoreBase } from '../../../common/FundCardStoreBase';
import { IRedirectButtonConfiguration } from '../models';
import { IRedirectButtonProps } from '@kurtosys/ksys-app-components/dist/components/base/RedirectButton/models';
export class RedirectButtonStore extends FundCardStoreBase {
	static componentKey: 'redirectButton' = 'redirectButton';

	@computed
	get configuration(): IRedirectButtonConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(RedirectButtonStore.componentKey);
		}
	}

	@action
	async initialize(): Promise<void> {

	}

	getRedirectButtonProps = (id?: string, props?: IRedirectButtonProps): IRedirectButtonProps => {
		const response: IRedirectButtonProps = {
			...props,
			hide: true,
		};
		const { appStore } = this.storeContext;
		const { appComponentConfiguration } = appStore;
		if (id) {
			if (appComponentConfiguration) {
				const { redirects = [] } = appComponentConfiguration;
				const appRedirect = redirects.find(appRedirect => appRedirect.id === id);
				if (appRedirect) {
					const { redirect, payloadQuery } = appRedirect;
					if (redirect) {
						response.redirect = redirect;
						if (payloadQuery) {
							const payload = this.getQueryValue(payloadQuery);
							response.payload = payload;
						}
						delete response.hide;
					}
					else {
						console.warn(`No redirect object available on App Redirect with the id of "${ id }", redirect button will be hidden`);
					}
				}
				else {
					console.warn(`No redirect found on the App with the id of "${ id }", redirect button will be hidden`);
				}
			}
		}
		else {
			console.warn(`No redirect id provided, redirect button will be hidden`);
		}
		return response;
	}
}