import React, { Component } from 'react';
import {
	IDefinitionListProps,
	IDefinitionListState,
} from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import DefinitionListComponent from '@kurtosys/ksys-app-components/dist/components/base/DefinitionList';

@inject('appStore')
@observer
export class DefinitionList extends Component<IDefinitionListProps, IDefinitionListState> {
	static configurationKey: 'definitionList' = 'definitionList';
	static styleKey: 'definitionList' = 'definitionList';
	constructor(props: IDefinitionListProps) {
		super(props);
	}
	render() {
		const { className, definitionListStore } = this.props;

		if (!definitionListStore) {
			return null;
		}

		const { definitionListProps } = definitionListStore;

		return (
			<Wrapper className={ className }>
				<DefinitionListComponent { ...definitionListProps } />
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(DefinitionList, 'definitionList');

