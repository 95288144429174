import React, { Component } from 'react';
import {
	IRedirectButtonProps,
	IRedirectButtonState,
} from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import RedirectButtonComponent from '@kurtosys/ksys-app-components/dist/components/base/RedirectButton';

@inject('appStore', 'redirectButtonStore')
@observer
export class RedirectButton extends Component<IRedirectButtonProps, IRedirectButtonState> {
	static configurationKey: 'redirectButton' = 'redirectButton';
	static styleKey: 'redirectButton' = 'redirectButton';
	constructor(props: IRedirectButtonProps) {
		super(props);
	}
	render() {
		const { className, redirectButtonStore, redirectId, childProps } = this.props;

		if (!redirectButtonStore) {
			return null;
		}
		const { getRedirectButtonProps } = redirectButtonStore;
		const redirectButtonProps = getRedirectButtonProps(redirectId, childProps);
		return (
			<Wrapper className={ className }>
				<RedirectButtonComponent { ...redirectButtonProps } />
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(RedirectButton, 'redirectButton');

