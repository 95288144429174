import { computed, action, observable } from 'mobx';
import { FundCardStoreBase } from '../../../common/FundCardStoreBase';
import { IDocumentImageConfiguration } from '../models';
import { IDocumentImageConfigProps } from '../models/IDocumentImageConfigProps';
import { IImageProps } from '@kurtosys/ksys-app-components/dist/components/base/Image/models';
import { deepMergeObjectsWithOptions } from '@kurtosys/ksys-app-template/dist/utils/object';
import { KurtosysApiStore } from '../../App/stores/KurtosysApiStore';
import { utils } from '@kurtosys/ksys-app-template/dist';

export class DocumentImageStore extends FundCardStoreBase {
	static componentKey: 'documentImage' = 'documentImage';

	@observable imagePropsByIdentifier: { [prop: string]: IImageProps } = {};

	@computed
	get configuration(): IDocumentImageConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(DocumentImageStore.componentKey);
		}
	}

	@action
	async initialize(): Promise<void> {

	}

	async getImageSourceByCode(kurtosysApiStore: KurtosysApiStore, clientCode: string): Promise<string | undefined> {
		try {
			if (kurtosysApiStore && clientCode) {
				const response = await kurtosysApiStore.client.documents.retrieve.execute({ body: { clientCode } }) as Blob;
				if (response) {
					return await this.convertBlobToURL(response) as string;
				}
			}
		}
		catch (e) {
			console.error('Failed to load image from client code:', clientCode);
		}
		return;
	}

	async getImageSourceByProperty(kurtosysApiStore: KurtosysApiStore, property: string, value: string): Promise<string | undefined> {
		try {
			if (kurtosysApiStore && property && value) {
				const response = await kurtosysApiStore.client.documents.retrieveByProperty.execute({ urlParams: [property, value] });
				if (response && response.type !== 'application/zip') {
					return this.convertBlobToURL(response) as string;
				}
			}
		}
		catch (e) {
			console.error('Failed to load image from property', { property, value });
		}
		return;
	}

	convertBlobToURL(file: Blob): string | ArrayBuffer | null {
		try {
			// Had issues uploading to local, with Property 'webkitURL' does not exist on type 'Window'
			// Issue is tracked down to package typescript-json-schema using a old version of typescript that does not have the webkitURL interface
			// Once they release v0.42.1 then this can be removed
			const urlCreator = (window as any).webkitURL || window.URL;
			return urlCreator.createObjectURL(file);
		}
		catch (e) {
			console.error('Failed to convert image data to url source');
		}
		return null;
	}

	@action
	updateImageProps(key: string, props: IImageProps) {
		const propsByIdentifier = { ...this.imagePropsByIdentifier };
		propsByIdentifier[key] = props;
		this.imagePropsByIdentifier = propsByIdentifier;
	}

	appendToKey(key: string, text: string) {
		if (key !== '') {
			return `${ key }-${ text }`;
		}
		return text;
	}

	getImageProps = (documentImageProps: IDocumentImageConfigProps) => {
		const imageKey = this.getImageKey(documentImageProps, true);
		return imageKey && this.imagePropsByIdentifier && this.imagePropsByIdentifier[imageKey];
	}

	getImageKey = (documentImageProps: IDocumentImageConfigProps, applyOverride = false): string => {
		if (applyOverride) {
			const imageProps = this.getDocumentImageConfigProps(documentImageProps);
			if (imageProps) {
				documentImageProps = imageProps;
			}
		}
		let key = '';
		if (documentImageProps) {
			if (documentImageProps.identifierType) {
				key = this.appendToKey(key, documentImageProps.identifierType);
			}
			if (documentImageProps.identifierMetaProperty) {
				key = this.appendToKey(key, documentImageProps.identifierMetaProperty);
			}
			if (documentImageProps.identifierValue) {
				key = this.appendToKey(key, documentImageProps.identifierValue);
			}
		}
		return key;
	}

	getDocumentImageConfigProps(overridingProps: IDocumentImageConfigProps): IDocumentImageConfigProps | undefined {
		let config;
		if (this.configuration) {
			config = this.mergeQueriesAndProps<IDocumentImageConfigProps>(this.configuration);
		}
		return deepMergeObjectsWithOptions({ arrayMergeStrategy: 'DeepMerge' }, config, overridingProps) as IDocumentImageConfigProps;;
	}

	@action
	async setImageProps(overridingProps: IDocumentImageConfigProps): Promise<void> {
		const documentImageProps = this.getDocumentImageConfigProps(overridingProps);
		if (documentImageProps) {
			const props: IImageProps = {
				baseUrl: '',
			};
			if (documentImageProps.identifierType && documentImageProps.identifierValue) {
				const { kurtosysApiStore } = this.storeContext;
				switch (documentImageProps.identifierType) {
					case 'meta':
						if (documentImageProps.identifierMetaProperty) {
							props.src = await this.getImageSourceByProperty(kurtosysApiStore, documentImageProps.identifierMetaProperty, documentImageProps.identifierValue);
						}
						break;
					case 'filename':
						props.src = await this.getImageSourceByProperty(kurtosysApiStore, 'filename', documentImageProps.identifierValue);
						break;
					case 'clientCode':
					default:
						props.src = await this.getImageSourceByCode(kurtosysApiStore, documentImageProps.identifierValue);
						break;
				}
			}
			if (!utils.typeChecks.isNullOrEmpty(documentImageProps.alt)) {
				props.alt = documentImageProps.alt;
			}
			if (props.src !== undefined || props.alt !== undefined) {
				this.updateImageProps(this.getImageKey(documentImageProps), props);
			}
		}
	}
}