import React, { Component } from 'react';
import {
	IDocumentDownloadProps,
	IDocumentDownloadState,
} from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import DocumentDownloadComponent from '@kurtosys/ksys-app-components/dist/components/file/DocumentDownload';

@inject('appStore')
@observer
export class DocumentDownload extends Component<IDocumentDownloadProps, IDocumentDownloadState> {
	static configurationKey: 'documentDownload' = 'documentDownload';
	static styleKey: 'documentDownload' = 'documentDownload';
	constructor(props: IDocumentDownloadProps) {
		super(props);
	}
	render() {
		const { className, documentDownloadStore } = this.props;
		if (!documentDownloadStore) {
			return null;
		}
		const { documentDownloadProps } = documentDownloadStore;

		return (
			<Wrapper className={ className }>
				<DocumentDownloadComponent { ...documentDownloadProps } />
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(DocumentDownload, 'documentDownload');

